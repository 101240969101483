import ApiService from './ApiService';
import axios from 'axios';

export default class AuthService {
    fetchUserNonce(visitorId, userAgent, email) {
        return axios.post(`${ApiService.getHost()}/user-nonce`, {
            visitor_id: visitorId,
            device: userAgent,
            email: email
        });
    }

    doLogin(estateId, visitorId, email, nonce, nonceSignature) {
        return axios.post(`${ApiService.getHost()}/login`, {
            estate_id: estateId,
            visitor_id: visitorId,
            email: email,
            nonce: nonce,
            nonce_signature: nonceSignature,
            platform: 1,
        });
    }

    verifyEmail(userId, hash, params) {
        return axios.get(`${ApiService.getHost()}/email/verify/${userId}/${hash}${params}`, {
            headers: ApiService.getHeaders()
        });
    }

    forgotPassword(email) {
        return axios.post(`${ApiService.getHost()}/forgot-password`, {
            email: email
        });
    }

    resetPassword(email, salt, pkey, dataSignature, token) {
        return axios.post(`${ApiService.getHost()}/reset-password`, {
            email: email,
            salt: salt,
            pkey: pkey,
            data_signature: dataSignature,
            token: token
        });
    }

    doSignUp(estateId, name, email, dwelling, phone, communications, termsAccepted, privacyAccepted, salt, pkey, data_signature) {
        return axios.post(`${ApiService.getHost()}/sign-up`, {
            estate_id: estateId,
            name: name,
            email: email,
            dwelling: dwelling,
            phone: phone,
            communications: communications,
            terms_accepted: termsAccepted,
            privacy_accepted: privacyAccepted,
            salt: salt,
            pkey: pkey,
            data_signature: data_signature
        });
    }
}