<template>
    <div class="h-screen">
        <div class="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center h-full" style="background-color: rgb(239, 243, 248);">
            <div class="surface-card p-4 shadow-2 border-round w-full lg:w-5">
                <div v-show="!success">
                    <p class="font-bold mb-4">Rellena los siguientes campos para cambiar tu contraseña.</p>
                    <label for="email" class="block text-900 font-medium mb-2">Email</label>
                    <InputText v-model.trim="v$.email.$model" id="email" type="email" class="w-full mb-2" :class="{'p-invalid': v$.email.$error}" required />
                    <div v-for="error of v$.email.$errors" :key="error.$uid" class="mb-4">
                        <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                    </div>

                    <label for="password" class="block text-900 font-medium mb-2">Contraseña <i class="pi pi-info-circle ml-1" v-tooltip="'Mínimo 8 caracteres, 1 minúscula, 1 mayúscula, 1 número, 1 símbolo'" /></label>
                    <InputText v-model="v$.password.$model" id="password" type="password" class="w-full mb-2" required />
                    <div v-for="error of v$.password.$errors" :key="error.$uid" class="mb-4">
                        <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                    </div>

                    <label for="password2" class="block text-900 font-medium mb-2">Repite la contraseña</label>
                    <InputText v-model="v$.password2.$model" id="password2" type="password" class="w-full mb-2" required />
                    <div v-for="error of v$.password2.$errors" :key="error.$uid" class="mb-4">
                        <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                    </div>

                    <div class="mb-4" v-if="resetError"><small class="p-error font-bold">{{ resetError }}</small></div>

                    <Button label="Cambiar mi contraseña" icon="pi pi-check" class="w-full mt-4" @click="resetPassword" :disabled="canSend"></Button>
                </div>
                <div v-show="success">
                    <div class="text-center mb-2">
                        <div class="text-900 text-3xl font-medium mb-5">Contraseña cambiada con éxito. Ya puedes iniciar sesión con tus nuevas credenciales.</div>
                        <Button label="Volver" icon="pi pi-arrow-left" @click="goLogin"></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthService from '@/service/AuthService';
import ErrorReportService from '@/service/ErrorReportService.js';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength, sameAs } from '@vuelidate/validators';
import ValidationMessages from '@/validationMessages';
import CustomValidators from '@/customValidators';

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            token: null,
            email: '',
            password: '',
            password2: '',
            working: false,
            resetError: null,
            error: false,
            success: false
        };
    },
    authService: null,
    errorReportService: null,
    created() {
        this.authService = new AuthService();
        this.errorReportService = new ErrorReportService();
        this.validationMessages = ValidationMessages;

        if (!this.$route.params.token) {
            this.$watch(
                () => this.$route.params,
                () => {
                    this.token = this.$route.params.token;
                },
            )
        } else {
            this.token = this.$route.params.token;
        }
    },
    validations() {
        return {
            email: { required, email },
            password: { required, minLength: minLength(8), password: CustomValidators.password },
            password2: { required, sameAs: sameAs(this.password) },
        };
    },
    computed: {
        canSend() {
            return this.v$.email.$errors.length !== 0
                || this.v$.password.$errors.length !== 0
                || this.v$.password2.$errors.length !== 0
                || this.email.length === 0
                || this.password.length === 0
                || this.password2.length === 0
                || !this.token
                || this.working;
        }
    },
    methods: {
        resetPassword() {
            if (!this.working && this.token) {
                this.working = true;
                this.resetError = null;
                let w = new Worker('/pwhash-worker.js');
                let salt;

                this.authService.fetchUserNonce(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.email
                ).then(response => {
                    if (response.data.salt && response.data.nonce) {
                        salt = window.sodium.from_hex(response.data.salt);
                        w.postMessage([salt, this.password]);
                    } else {
                        this.resetError = 'ERROR R-04: Ha ocurrido un error al cambiar la contraseña';
                    }
                }).catch(error => {
                    console.log(error);
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request, {email: this.email}),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.working = false;
                    this.resetError = 'ERROR R-01: Ha ocurrido un error al cambiar la contraseña';
                });

                w.onmessage = (e) => {
                    let kp = window.sodium.crypto_sign_seed_keypair(window.sodium.from_hex(e.data));
                    let salt_signature = window.sodium.crypto_sign_detached(salt, kp.privateKey);

                    this.authService.resetPassword(
                        this.email,
                        window.sodium.to_hex(salt),
                        window.sodium.to_hex(kp.publicKey),
                        window.sodium.to_hex(salt_signature),
                        this.token
                    ).then(response => {
                        this.working = false;

                        if (response.data.result === 'passowrds.reset') {
                            this.success = true;
                        } else {
                            this.resetError = 'ERROR R-03: Ha ocurrido un error al cambiar la contraseña';
                        }
                    }).catch(error => {
                        console.log(error);
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request, {email: this.email}),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.working = false;
                        this.resetError = 'ERROR R-02: Ha ocurrido un error al cambiar la contraseña';
                    });
                };
            }
        },
        goLogin() {
            this.$router.replace({ name: 'login' });
        }
    }
}
</script>