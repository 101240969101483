import { helpers } from '@vuelidate/validators';

export default {
    password: helpers.regex(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!·@#$%^&+=.:\-_*()/,;[\]{}'"`¿¡?<>ç])([a-zA-Z0-9!·@#$%^&+=*.:\-_()/,;[\]{}'"`¿¡?<>ç]){8,}$/),
    iban: (value) => validateIBAN(value),
    slug: helpers.regex(/^[a-zA-Z0-9-]{1,}$/),
    validPageType: (value) => validatePageType(value),
    uniqueSlug: (value, siblings, vm) => validateUniqueSlug(value, vm)
};

function validateIBAN(value) {
    if (value) {
        let iban = value;
        let validationResult;

        iban = iban.replace(/\s/g, "");

        if (iban.length !== 24) {
                return {'not-valid-iban': {value: value}};
        }

        let letter1, letter2, numLetter1, numLetter2;
        letter1 = iban.substring(0, 1);
        letter2 = iban.substring(1, 2);

        numLetter1 = getNumIBANLetter(letter1);
        numLetter2 = getNumIBANLetter(letter2);

        iban = String(numLetter1) + String(numLetter2) + iban.substring(2);

        iban = iban.substring(6) + iban.substring(0, 6);

        validationResult = modulo97(iban);

        if (validationResult === '1') {
                return true;
        }

        return false;
    }
}

function getNumIBANLetter(letter) {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return letters.search(letter) + 10;
}

function modulo97(iban) {
    const parts = Math.ceil(iban.length / 7);
    let remainer = "";

    for (let i = 1; i <= parts; i++) {
        remainer = String(parseFloat(remainer + iban.substr( (i - 1) * 7, 7)) % 97);
    }

    return remainer;
}

function validatePageType(pt) {
    const pageTypes = ['basic', 'custom'];

    return pageTypes.includes(pt.value);
}

function validateUniqueSlug(slug, vm) {
    return !vm.allSlugs.includes(slug);
}